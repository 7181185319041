<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.nickName" placeholder="名称"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.userId" placeholder="编号"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.userName" placeholder="手机号"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      bordered
    >
      <span slot="action1" slot-scope="scope">
        <span style="width:80px;float: left;">{{ scope.nickName }}</span>
        <span style="margin-left:82px;float: left;">
          <a v-if="scope.avatar != null && scope.avatar!=''" @click="zoom(scope.avatar)">
            <a-avatar shape="square"
              style="width:40px;height:40px;margin-left:5px;margin-top:2px;"
              :src="scope.avatar" />
          </a>
        </span>
      </span>
      <span slot="action2" slot-scope="scope">
        <span v-for="(userPartner,index) in scope.partnerList" :key="index">
          <div>{{userPartner.channelName}} - {{userPartner.partnerId}}</div>
        </span>
      </span>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="avatarImag"/>
  </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '用户管理',
          url: ''
        }
      ],
      visible: false,
      avatarImag: '',
      isQueryLoading: false,
      form: {
        nickName: '',
        userId: '',
        userName: ''
      },
      account: {},
      columns: [
        { title: '编号', key: 'id', dataIndex: 'id' },
        { title: '姓名', key: '', scopedSlots: { customRender: 'action1' } },
        { title: '账号', key: 'userName', dataIndex: 'userName' },
        { title: '渠道信息', key: '', scopedSlots: { customRender: 'action2' }, width: 400 },
        { title: '注册时间', key: 'gmtCreate', dataIndex: 'gmtCreate' }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.nickName = ''
      this.form.userId = ''
      this.form.userName = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryUserFrontList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.avatarImag = scope
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
